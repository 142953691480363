import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import uminek_ooo00 from '../images/uminek.ooo_00.jpg'

const PV = () => (
  <Layout>
    <h1>PVが完成しました！</h1>

<a href="https://www.youtube.com/watch?v=MTayM2lkcrI" target="_blank" rel="noopener noreferrer"><img src={uminek_ooo00} alt="uminek.ooo_movie00"/></a>

<br />
<a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/watch?v=MTayM2lkcrI">【PV】uminek.ooo/バーチャルヨットセーラー</a>
<br />
<br />
<a target="_blank" rel="noopener noreferrer" href="https://www.nicovideo.jp/watch/sm33944400">【ニコニコ動画】【PV】uminek.ooo/バーチャルヨットセーラー</a>

<br />

<p><br />次回動画では、今後どんなことをやっていきたいのかについて
動画でお伝えしようと思います！</p>

    <Link to="/">トップへ戻る</Link>
  </Layout>
)

export default PV




